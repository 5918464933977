import './App.css';
import LandingPage from './LandingPage';
import { Route, Routes } from 'react-router';
import TeamPage from './Team';
import BlogPage from './Blog';

function App() {
  return (
    <Routes>
      <Route path='/' element={<LandingPage />} />
      <Route path='/team' element={<TeamPage />} />
      <Route path='/blog/press-release-sept-2024' element={<BlogPage />} />
    </Routes>
  );
}

export default App;
