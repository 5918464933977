import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';

const items = [
  {
    icon: <GppGoodOutlinedIcon />,
    title: 'Enhanced Detection',
    description:
      'Automate checking for retractions by using Argos\' retractions API. More easily check the involvement of your authors in previous retractions.',
    imageLight: 'url("/static/images/templates/templates-images/graphic-scitility-homepage.png")',
    imageDark: 'url("/static/images/templates/templates-images/graphic-scitility-homepage.png")',
  },
  {
    icon: <WarningAmberOutlinedIcon />,
    title: 'Preventative Measures',
    description:
      'Daily reports that uncover your potentially problematic publications.',
    imageLight: 'url("/static/images/templates/templates-images/scitility-design-explanation.png")',
    imageDark: 'url("/static/images/templates/templates-images/scitility-design-explanation.png")',
  },
  {
    icon: <BiotechOutlinedIcon />,
    title: 'Improved Research Integrity',
    description:
      'Strengthen trust in the scientific literature and research practices.',
    imageLight: 'url("/static/images/templates/templates-images/argos-search-result.png")',
    imageDark: 'url("/static/images/templates/templates-images/argos-search-result.png")',
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index: number) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="argos" sx={{ py: { xs: 2, sm: 2 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <div>
            <Stack direction={{md: "row", sx: "column"}}>
              <Typography
                component="span"
                variant="h1"
                sx={{
                  fontSize: 'clamp(3rem, 10vw, 4rem)',
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                }}
              >
                Introducing Argos
              </Typography>
              <Button href='/argos' variant='contained' sx={{ pl: {md: 5}, pr: 5, mr: 2, ml: {md: 10}, mt: 2, backgroundColor: "#003366" }}>TRY OUT</Button>
            </Stack>
            <Typography
              variant="h4"
              color="text.secondary"
              sx={{  mb: { xs: 2, sm: 4 }, mt: 3 , alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(2rem, 10vw, 2rem)' }}
            >
              A comprehensive retraction service that empowers researchers, institutions, societies, funders, and publishers to uphold the highest standards of scientific integrity.  With daily updates, personalized retraction alerts, and seamless integration into workflows via an API, Argos swiftly identifies retracted articles and flags areas of concern. Fostering a culture of accountability and trust.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
